@import url("../../../node_modules/maplibre-gl/dist/maplibre-gl.css");

body {
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
#map {
  width: 100%;
  height: 100vh;
  background-color: #ccc;
}

/* Pop up */

.marker {
  background-image: url("../imgs/rutalogo-black-resized.png");
  background-size: cover;
  width: 50px;
  height: 70px;
  cursor: pointer;
}
.maplibre-popup {
  max-width: 200px;
}
.maplibregl-popup-content {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding: 0.1em 0.5em;
}

.maplibregl-popup-content h3 {
  font-size: 1em;
}

.maplibregl-popup-content a {
  text-decoration: none;
  border-bottom: 2px dotted #262626;
  color: #262626;
}

/* CSS to create a modal without JS */
.hidden {
  display: none;
}


.details-modal {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgba(black, 0.2);
  left: 50%;
  max-width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.details-modal-close {
  align-items: center;
  color: #111827;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
}
.details-modal-close   svg {
  display: block;
}

.details-modal .details-modal-content {
  padding: 1em;
  pointer-events: all;
  overflow: auto;
}

.details-modal-content h3 {
  padding: 0;
  margin: 0.7em 0 0.5em 0;
}

.details-modal-content p.about {
    text-align: right;
    font-size: smaller;
}

.details-modal-content a {
    color: darkred;
    text-decoration: none;
}

.details-modal-content a:hover {
    text-decoration: underline;
}